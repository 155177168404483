<template>
  <div>
    <FormError :errors="errors.save" />
    <div class="flex justify-between">
      <Input
        :label="$t('global_firstname')"
        :editable="editable"
        v-model="account.firstName"
        classAdd="w-full mr-1 flex flex-col justify-start items-start relative"
        :error="errors.firstName"
      />
      <Input
        :label="$t('global_lastname')"
        :editable="editable"
        v-model="account.lastName"
        classAdd="w-full ml-1 flex flex-col justify-start items-start relative"
        :error="errors.lastName"
      />
    </div>

    <Input
      :label="$t('global_email')"
      :editable="false"
      v-model="account.emailAddress"
      classAdd="mt-5 flex flex-col justify-start items-start relative"
    />
    <div class="mt-5 flex flex-col justify-start items-start">
      <label class="text-sm font-proximaMedium" for="phoneNumber"
        >{{ $t("global_phone_number") }} *</label
      >
      <input
        v-if="!editable"
        type="text"
        :readonly="!editable"
        v-model="account.phoneNo"
        class="w-full rounded-sm px-3 py-3 my-1 text-sm"
      />

      <vue-tel-input
        v-if="editable"
        id="phoneNumber"
        required
        v-model="account.phoneNo"
        validCharactersOnly
        @validate="telValidate"
        class="w-full"
        :readonly="!editable"
      />
      <FormError :errors="errors.phoneNo" />
    </div>

    <div class="w-full">
      <InputRadio
        class="mt-4"
        :title="$t('account_business_type')"
        opt1="Business"
        opt2=" Self-employed"
        @input="inputBusinessType"
      />
    </div>

    <Input
      :label="$t('account_business_name')"
      :editable="editable"
      v-model="account.businessName"
      class="mt-5 flex flex-col justify-start items-start relative"
      :error="errors.businessName"
    />

    <Input
      :label="$t('account_business_tax_id') + '*'"
      :editable="editable"
      v-model="account.taxID"
      class="mt-5 flex flex-col justify-start items-start relative"
      :error="errors.taxID"
    />

    <Input
      :label="$t('account_website')"
      :editable="editable"
      v-model="account.website"
      class="mt-5 flex flex-col justify-start items-start relative"
      :error="errors.website"
    />

    <Input
      :label="$t('global_street_address')"
      :editable="editable"
      v-model="account.streetAddress"
      class="mt-5 flex flex-col justify-start items-start relative"
      :error="errors.streetAddress"
    />

    <div class="mt-5 flex justify-between">
      <div class="w-full mr-1 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="country">{{
          $t("global_country")
        }}</label>
        <select
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          v-model="account.country"
          @change="inputSelectCountry()"
        >
          <option value="" disabled selected>
            {{ $t("global_select_options") }}
          </option>
          <option v-for="item in countriesList" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
        <FormError :errors="errors.country" />
      </div>
      <div class="w-full ml-1 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="state">{{
          $t("global_state")
        }}</label>
        <select
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          v-model="account.state"
        >
          <option value="account.state" disabled selected>
            {{ $t("global_select_options") }}
          </option>
          <option v-for="item in statesList" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
        <FormError :errors="errors.state" />
      </div>
    </div>
    <div class="mt-5 flex justify-between">
      <Input
        :label="$t('global_city')"
        :editable="editable"
        v-model="account.city"
        classAdd="w-full mr-1 flex flex-col justify-start items-start relative"
        :error="errors.city"
      />
      <Input
        :label="$t('global_postal_code')"
        :editable="editable"
        v-model="account.postalCode"
        classAdd="w-full ml-1 flex flex-col justify-start items-start relative"
        :error="errors.postalCode"
      />
    </div>
  </div>
</template>

<script>
import InputSelect from "../../forms/InputSelect.vue";
import Input from "../../forms/Input_.vue";
import InputRadio from "../../forms/InputRadio.vue";
import "vue-tel-input/dist/vue-tel-input.css";
import { VueTelInput } from "vue-tel-input";
import FormError from "../../warning/FormError.vue";

import { updateServiceProvider } from "../../../services/serviceProviderService";
import { getStates } from "../../../services/stateServices";
import { getCountries } from "../../../services/countriesServices";

export default {
  props: { editable: { type: Boolean, default: false } /* data: {}  */ },
  components: {
    InputSelect,
    InputRadio,
    VueTelInput,
    Input,
    FormError,
  },
  data() {
    return {
      countryCode: "",
      errors: {},
      countriesList: [],
      statesList: [],
      coordinates: { lng: 3.05997, lat: 36.7762 },
      options: {
        placeholder: this.$t("global_phone_number_placeholder"),
      },
    };
  },
  computed: {
    account() {
      console.log("*********", this.$store.state.accountState);
      return this.$store.state.accountState;
    },
  },
  async created() {
    const a = await getCountries();
    this.countriesList = a.data;
    console.log(this.countriesList);
    if (this.account.country) {
      const c = await getStates(this.account.country);
      this.statesList = c.data;
      console.log(this.statesList);
    }
    //coordinates: { lng: 3.05997, lat: 36.7762 },
    this.$getLocation({}).then((coordinates) => {
      this.coordinates = coordinates;
    });
    console.log('//////',this.coordinates)
  },
  methods: {
    telValidate(telnumber) {
      this.account.phoneNo = telnumber.number;
    },

    inputBusinessType(val) {
      this.selfEmployed = val == 2 ? true : false;
    },

    async inputSelectCountry() {
      const a = await getStates(this.account.country);
      this.statesList = a.data;
    },

    validate() {
      const errors = {};

      if (this.account.phoneNo === "")
        errors.phoneNo = "Phone number is required";

      if (this.account.firstName.trim() === "")
        errors.firstName = "FirstName  is required";
      if (this.account.lastName.trim() === "")
        errors.lastName = "LastName  is required";
      if (this.account.streetAddress.trim() === "")
        errors.streetAddress = "Address  is required";
      if (this.account.country === "") errors.country = "Country is required";
      if (this.account.state === "") errors.state = "State  is required";
      if (this.account.city.trim() === "") errors.city = "City  is required";
      if (this.account.postalCode.trim() === "")
        errors.postalCode = this.$t("global_error_postal_code");
      if (this.account.businessName.trim() === "")
        errors.businessName = this.$t("account_error_business_name");
      if (this.account.taxID.trim() === "")
        errors.taxID = this.$t("account_error_business_tax_id");

      return Object.keys(errors).length === 0 ? null : errors;
    },
    async submitForm(image) {
      try {
        await updateServiceProvider(image, this.account,this.coordinates,);
      } catch (error) {
        console.log(error);
        this.errors.save = "save error.";
      }
    },
  },
};
</script>

<style>
.vti__dropdown {
  border: 1px solid #ccc;
  border-radius: 0.125rem 0 0 0.125rem;
}
.vue-tel-input {
  border-radius: 0.125rem;
  border: none;
  font-size: 0.875rem;
}
.vti__input {
  padding: 0.75rem 10px 0.75rem;
  border-radius: 0 0.125rem 0.125rem 0;
}
.vue-tel-input:focus-within {
  box-shadow: none;
  border-color: none;
  outline: auto;
}
</style>
